.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* Networking area position picker menu */

.rs-picker-menu {
  width: 300px;
  border-radius: 0;
  border: 1px solid black;
  border-top: none;
  box-shadow: none;
  margin: 0;
  transition: 0 all !important;
}

.rs-picker-menu ul li:hover * {
  background-color: #000 !important;
  transition: none;
  color: #fff;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#virtualGuide {
  font-family: open_sansregular, Arial, Helvetica, sans-serif;
}

#virtualGuide .nav {
  flex-wrap: nowrap;
  margin-left: -1px;
}

#virtualGuide .nav-tabs .nav-link {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 0;
}

#virtualGuide .rs-nav-item-content {
  padding: 5px 5px !important;
}

#virtualGuide .tab-content {
  height: calc(100% - 123px);
}

#virtualGuide .tab-pane {
  height: 100%;
}

/* https://stackoverflow.com/a/55326037/4558361 */

input {
  filter: none;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.rs-modal-header-close {
  outline: 0;
  position: absolute;
  right: 22px;
  top: 20px;
  font-size: 23px;
  line-height: 1.66666667;
  color: #000;
  width: 30px;
  padding: 0 2px;
  background: none;
  border-style: none;
  margin: 25px 35px;
}

.tooltip[data-popper-reference-hidden="true"] {
  visibility: hidden;
  pointer-events: none;
}

.custom-backdrop {
  background-color: transparent !important;
  opacity: 0 !important;
}

iframe {
  pointer-events: none !important;
}

#hallplan {
  pointer-events: auto !important;
}

#jsd-widget {
  pointer-events: auto !important;
}

#orgaIframe {
  pointer-events: auto !important;
}

#magazineIframe {
  pointer-events: auto !important;
}